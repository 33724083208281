import { createSlice } from "@reduxjs/toolkit";
import { setCookie } from "../helpers";

import { setThxMessage } from "./thxSlice";
import {
  getPageQuest as getPageQuestApi,
  getQuest as getQuestApi,
  getComments as getCommentsApi,
  buyQuest as buyQuestApi,
} from "../api";

const initialState = {
  data: {
    main: {
      data: {},
      isLoading: false,
      isLoaded: false,
      error: null,
    },
    howItsWorks: {
      data: {},
      isLoading: false,
      isLoaded: false,
      error: null,
    },
    battery: {
      data: {},
      isLoading: false,
      isLoaded: false,
      error: null,
    },
    comments: {
      data: [],
      isLoading: false,
      isLoaded: false,
      error: null,
    },
    buy: {
      data: {},
      isLoading: false,
      isLoaded: false,
      error: null,
    },
  },
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const questSlice = createSlice({
  name: "quest",
  initialState,
  reducers: {
    getQuestInfoStart: (state) => {
      state.data.howItsWorks = {
        ...state.howItsWorks,
        isLoading: true,
      };
      state.data.battery = {
        ...state.battery,
        isLoading: true,
      };
      state.isLoading = true;
      state.isLoaded = false;
    },
    getQuestInfoSuccess: (state, { payload }) => {
      state.data.howItsWorks = {
        data: {
          title: payload.how_it_works.title || "",
          desc: payload.how_it_works.description || "",
          items: payload.how_it_works.steps.map((step) => {
            return {
              id: step.id,
              title: step.title || "",
              imgUrl: step.image || "",
            };
          }),
        },
        isLoaded: true,
        isLoading: false,
        error: null,
      };
      state.data.battery = {
        data: {
          items:
            !!payload.how_it_works && Array.isArray(payload.how_it_works.items)
              ? payload.how_it_works.items.map((item) => {
                  return {
                    id: item.id,
                    desc: item.text || "",
                    imgUrl: item.image || "",
                  };
                })
              : [],
        },
        isLoaded: true,
        isLoading: false,
        error: null,
      };
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getQuestInfoFailure: (state, { payload }) => {
      state.data.howItsWorks = {
        ...state.data.howItsWorks,
        isLoading: false,
        error: payload.error,
      };
      state.data.battery = {
        ...state.data.battery,
        isLoading: false,
        error: payload.error,
      };
      state.isLoading = false;
      state.isLoaded = false;
      state.error = payload.error;
    },
    getQuestStart: (state) => {
      state.data.main = {
        ...state.data.main,
        isLoading: true,
      };
      state.isLoading = true;
      state.isLoaded = false;
    },
    getQuestSuccess: (state, { payload }) => {
      state.data.main = {
        data: {
          slider: payload.images.map((pic, picIdx) => ({
            id: picIdx,
            imgUrl: pic || "",
          })),
          info: {
            title: payload.title || "",
            city: payload.city || "",
            desc: payload.description || "",
            price: payload.price,
          },
          way: {
            rating: payload.info.avr_rating,
            duration: payload.info.duration || { min: 0, max: 0 },
            distance: payload.info.distance || 0,
            start: payload.info.start || "",
            finish: payload.info.finish || "",
            specifics: payload.info.specifics,
            geo: payload.info.geo,
          },
        },
        isLoaded: true,
        isLoading: false,
        error: null,
      };
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getQuestFailure: (state, { payload }) => {
      state.data.main = {
        ...state.data.main,
        isLoading: false,
        error: payload.error,
      };
      state.isLoading = false;
      state.isLoaded = false;
      state.error = payload.error;
    },
    getCommentsStart: (state) => {
      state.data.comments = {
        ...state.data.comments,
        isLoading: true,
        isLoaded: false
      };
      state.isLoading = true;
      state.isLoaded = false;
    },
    getCommentsSuccess: (state, { payload }) => {
      state.data.comments = {
        data: Object.keys(payload).map((key) => {
          const [year, month, date] = payload[key].created_at
            .split(" ")[0]
            .split("-");
          return {
            id: payload[key].id,
            author: payload[key].player_name,
            rating: payload[key].rating,
            message: payload[key].message,
            date: `${date}.${month}.${year}`,
          };
        }),
        isLoaded: true,
        isLoading: false,
        error: null,
      };
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getCommentsFailure: (state, { payload }) => {
      state.data.comments = {
        ...state.data.comments,
        isLoading: false,
        error: payload.error,
      };
      state.isLoading = false;
      state.isLoaded = false;
      state.error = payload.error;
    },
    buyQuestStart: (state) => {
      state.data.buy = {
        ...state.data.buy,
        isLoading: true,
      };
      state.isLoading = true;
      state.isLoaded = false;
    },
    buyQuestSuccess: (state, { payload }) => {
      state.data.buy = {
        data: {
          id: payload.id,
          name: payload.props.name,
          email: payload.props.email,
          message: payload.data.message,
        },
        isLoaded: true,
        isLoading: false,
        error: null,
      };
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    buyQuestFailure: (state, { payload }) => {
      state.data.buy = {
        ...state.data.buy,
        isLoading: false,
        error: payload.error,
      };
      state.isLoading = false;
      state.isLoaded = false;
      state.error = payload.error;
    },
  },
});

export const {
  getQuestInfoStart,
  getQuestInfoSuccess,
  getQuestInfoFailure,
  getQuestStart,
  getQuestSuccess,
  getQuestFailure,
  getCommentsStart,
  getCommentsSuccess,
  getCommentsFailure,
  buyQuestStart,
  buyQuestSuccess,
  buyQuestFailure,
} = questSlice.actions;

export const getQuest = (id, lang) => async (dispatch) => {
  dispatch(getQuestStart());
  try {
    const data = await getQuestApi(id, lang);
    dispatch(getQuestSuccess(data));
  } catch (err) {
    dispatch(getQuestFailure({ error: err.toString() }));
  }
};

export const getQuestInfo = (lang) => async (dispatch) => {
  dispatch(getQuestInfoStart());
  try {
    const data = await getPageQuestApi(lang);
    dispatch(getQuestInfoSuccess(data));
  } catch (err) {
    dispatch(getQuestInfoFailure({ error: err.toString() }));
  }
};

export const getComments = (id, lang) => async (dispatch) => {
  dispatch(getCommentsStart());
  try {
    const data = await getCommentsApi(id, lang);
    dispatch(getCommentsSuccess(data));
  } catch (err) {
    dispatch(getCommentsFailure({ error: err.toString() }));
  }
};

export const buyQuest = (id, props, lang, goToThx, goToUrl) => async (dispatch) => {
  dispatch(buyQuestStart());
  try {
    const data = await buyQuestApi(id, props, lang);

    setCookie(`payment_data_${data.paymentData.key}`, JSON.stringify(data.paymentData), 1);

    if (!!data.checkout_url) {
      goToUrl(data.checkout_url)
    }
    dispatch(buyQuestSuccess({ id, props, data }));
  } catch (err) {
    dispatch(buyQuestFailure({ error: err.toString() }));
  }
};

export const selectQuest = (state) => state.quest;

export default questSlice.reducer;
