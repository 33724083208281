import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import star from "../../assets/icons/star.svg";
import way from "../../assets/icons/way.svg";
import time from "../../assets/icons/time.svg";
import Button from "../UI/Button";
import { QUEST_PATH } from "../../constants";
import { useStringInArr } from "../../helpers";
import styles from "./QuestCard.module.scss";

const QuestCard = ({ className, quest }) => {
  const { t } = useTranslation();
  return (
    <div className={cn(className, styles.quest)}>
      <div className={styles.quest__image}>
        {!!quest.imgUrl && <img src={quest.imgUrl} alt={quest.id} />}
      </div>

      <div className={styles.quest__wrap}>
        <div className={styles.quest__top}>
          <div
            className={cn(
              styles.quest__rating,
              "b-content",
              "b-content_md",
              "b-content_gray"
            )}
          >
            <img src={star} alt="rating" />
            <p>{quest.rating}</p>
          </div>

          <div
            className={cn(
              styles.quest__time,
              "b-content",
              "b-content_md",
              "b-content_gray"
            )}
          >
            <img src={time} alt="time" />
            <p>
              {quest.duration.min} - {quest.duration.max}{" "}
              {useStringInArr(quest.duration.max, [t("questCard.duration.single"), t("questCard.duration.multi"), t("questCard.duration.multi")])}
            </p>
          </div>

          <div
            className={cn(
              styles.quest__way,
              "b-content",
              "b-content_md",
              "b-content_gray"
            )}
          >
            <img src={way} alt="way" />
            <p>{quest.way}</p>
          </div>
        </div>

        <div className={styles.quest__body}>
          <h4 className={cn(styles.quest__title, "h4")}>{quest.title}</h4>

          <div
            className={cn(styles.quest__desc, "b-content b-content_md")}
            title={quest.desc.replace(/&nbsp;/g, " ")}
          >
            <p>{quest.desc}</p>
          </div>
        </div>

        <div className={styles.quest__footer}>
          <Button
            className={styles.quest__buy}
            sm
            asLink
            to={`${QUEST_PATH()}${quest.id}`}
          >
            {t("questOrder.btns.buy")}
          </Button>

          <div className={styles.quest__price}>
            <span>{quest.price} {t("questOrder.currency")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestCard;
