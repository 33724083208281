import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Helmet } from 'react-helmet';
// import styles from './Quest.module.scss';
import QuestLayout from '../../components/QuestLayout';
import QuestSlider from '../../components/QuestSlider';
import QuestOrder from '../../components/QuestOrder';
import QuestMap from '../../components/QuestMap';
import HowItsWorks from '../../components/HowItsWorks';
import Battery from '../../components/Battery';
import QuestComments from '../../components/QuestComments';
import { getQuests } from '../../store/questsSlice';
import { getMenu } from '../../store/menuSlice';
import { getQuestInfo, getComments } from '../../store/questSlice';
import { HOME_PATH } from '../../constants';

const Quest = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id, lang } = useParams();
	const quest = useSelector((state) => state.quest);
	const quests = useSelector((state) => state.quests);
	const questCard = useSelector((state) => state.quests.data.find((q) => q.id === +id));
	const menu = useSelector((state) => state.menu);
	const socials = useSelector((state) => state.socials);

	const { i18n } = useTranslation();

	useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

	useEffect(() => {
		dispatch(getMenu(lang));
		dispatch(getQuests(lang));
		dispatch(getQuestInfo(lang));
		dispatch(getComments(id, lang));
	}, [lang]);

	useEffect(() => {
		if (quests.isLoaded && questCard === undefined) {
			history.push(HOME_PATH(lang));
			return;
		}
	}, [questCard]);

	useEffect(() => {
		if (quest.error !== null) {
			history.push(HOME_PATH(lang));
			return;
		}
	}, [quest, history, lang]);

	return (
		<QuestLayout menu={menu} socials={socials}>
			{quests.isLoaded && !!questCard && (
				<Helmet>
          <title>{"Swiss Quest::" + questCard.title}</title>
					<meta property="og:image:type" content="image/jpeg" />
					<meta property="og:image:width" content="302" />
					<meta property="og:image:height" content="103" />
					<meta property="og:image" content={questCard.imgUrl} />
					<meta property="og:image:secure_url" content={questCard.imgUrl} />
				</Helmet>
			)}

			{quests.isLoaded && !!questCard && (
				<>
					{!!questCard.page.slider.length && <QuestSlider slides={questCard.page.slider} />}

					<QuestOrder id={id} {...questCard.page.info} />

					<QuestMap {...questCard.page.way} />
				</>
			)}

			{quest.data.howItsWorks.isLoaded && (
				<HowItsWorks
					quest
					title={quest.data.howItsWorks.data.title}
					desc={quest.data.howItsWorks.data.desc}
					items={quest.data.howItsWorks.data.items}
				/>
			)}

			{quest.data.battery.isLoaded && <Battery quest items={quest.data.battery.data.items} />}

			{quest.data.comments.isLoaded && !!quest.data.comments.data.length && <QuestComments comments={quest.data.comments.data} />}
		</QuestLayout>
	);
};

export default Quest;
