import React from "react";
import cn from "classnames";
import { IMG_URL } from "../../constants";
import styles from "./AboutUs.module.scss";

const AboutUs = React.forwardRef(({ title = "", content = "" }, ref) => {
  const leftPictures = [
    {
      id: 0,
      image: "top",
      type: "jpeg",
    },
    {
      id: 1,
      image: "bottom",
      type: "jpg",
    },
  ];

  return (
    <section ref={ref} className={styles.section}>
      <div className="container">
        <div className="row">
          <div className={styles.section__left}>
            {!!leftPictures.length &&
              leftPictures.map((pic) => (
                <div className="row" key={pic.id}>
                  <div
                    className={cn("col-6", {
                      "offset-6": pic.image === "top",
                      "offset-sm-0": pic.image === "top",
                      "offset-sm-6": pic.image === "bottom",
                    })}
                  >
                    <div
                      className={cn(
                        styles.section__image,
                        styles[`section__image_${pic.image}`]
                      )}
                    >
                      <img
                        src={`${IMG_URL}/about-us/${pic.image}.${pic.type}`}
                        alt={pic.image}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className={styles.section__right}>
            <div className={styles.section__main}>
              <div className={styles.section__wrap}>
                <h2 className={cn(styles.section__title, "h1")}>{title}</h2>
                <div
                  className={cn(
                    styles.section__content,
                    "b-content b-content_lg"
                  )}
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default AboutUs;
