import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import styles from "./HomeLayout.module.scss";

import Sidebar from "../Sidebar";
import MobileSidebar from "../MobileSidebar";

const HomeLayout = ({
  children,
  menu,
  socials,
  activeMenuItem,
  onToggleMenu = () => {},
  onClickMenu = () => {},
}) => {
  let location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={styles.home}>

      <div className={styles.home__sidebarWrap}>
        <Sidebar
          className={styles.home__sidebar}
          activeMenuItem={activeMenuItem}
          menu={menu}
          onClickMenu={onClickMenu}
          socials={socials}
        />
      </div>

      <div className={styles.home__sidebarMobile}>
        <MobileSidebar
          menu={menu}
          onClickMenu={onClickMenu}
          onToggleMenu={onToggleMenu}
          socials={socials}
        />
      </div>

      <main className={styles.home__main} id="loyout">
        {children}
      </main>
    </div>
  );
};

export default HomeLayout;
