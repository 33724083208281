import { configureStore } from "@reduxjs/toolkit";
import questsReducer from "./questsSlice.js";
import homeReducer from "./homeSlice.js";
import questReducer from "./questSlice.js";
import menuReducer from "./menuSlice.js";
import socialsReducer from "./socialsSlice.js";
import appReducer from "./appSlice.js";
import thxReducer from "./thxSlice.js";
import langReducer from './langSlice.js';
import translationsReducer from "./translationsSlice.js";
import paymentsReducer from "./paymentsSlice.js";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    quests: questsReducer,
    quest: questReducer,
    home: homeReducer,
    menu: menuReducer,
    socials: socialsReducer,
    app: appReducer,
    thx: thxReducer,
    lang: langReducer,
    translations: translationsReducer,
    payments: paymentsReducer
  },
});
