import React, { useRef } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import styles from "./QuestMap.module.scss";
import starIcon from "../../assets/icons/star.svg";
import timeIcon from "../../assets/icons/time.svg";
import distanceIcon from "../../assets/icons/distance.svg";
import startIcon from "../../assets/icons/startWay.svg";
import finishIcon from "../../assets/icons/finishWay.svg";
import startMapIcon from "../../assets/icons/startWayMap.svg";
import finishMapIcon from "../../assets/icons/finishWayMap.svg";

const QuestMap = ({
  rating,
  duration,
  distance,
  start,
  finish,
  specifics,
  geo,
}) => {
  const { t } = useTranslation();
  const myMap = useRef(null);

  const handleMapLoad = () => {

    if (myMap.current) {
      myMap.current.setBounds(myMap.current.geoObjects.getBounds(), {
        checkZoomRange: true,
        zoomMargin: 9,
      });

      let mql = window.matchMedia("(max-width: 768px)");

      if (mql.matches) {
        myMap.current.behaviors.disable([
          "scrollZoom",
          "drag",
          "rightMouseButtonMagnifier",
        ]);
      }
    }
  };

  return (
    <section className={styles.section}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className={styles.section__left}>
              <YMaps query={{ apikey: "89bc318b-9d81-461d-81ae-8c80b915e405", lang: "en_US" }}>
                <Map
                  onLoad={(ymaps) => {
                    ymaps.ready(() => {
                      handleMapLoad(ymaps);
                    });
                  }}
                  instanceRef={(maps) => {
                    myMap.current = maps;
                  }}
                  className={styles.section__map}
                  defaultState={{ center: geo.start, zoom: 16 }}
                >
                  {geo.start.length === 2 && (
                    <Placemark
                      geometry={geo.start}
                      options={{
                        iconLayout: "default#image",
                        iconImageHref: startMapIcon,
                        iconImageSize: [52, 52],
                        iconImageOffset: [-26, -26],
                      }}
                    />
                  )}
                  {geo.finish.length === 2 && (
                    <Placemark
                      geometry={geo.finish}
                      options={{
                        iconLayout: "default#image",
                        iconImageHref: finishMapIcon,
                        iconImageSize: [52, 52],
                        iconImageOffset: [-26, -26],
                      }}
                    />
                  )}
                </Map>
              </YMaps>
            </div>
          </div>

          <div className="col-md-4">
            <div className={styles.section__main}>
              <div className={cn(styles.section__props, styles.props)}>
                <ul className={styles.props__list}>
                  <li className={styles.props__item}>
                    <img
                      src={starIcon}
                      alt="star"
                      className={styles.props__icon}
                    />
                    <p className={styles.props__text}>{rating}</p>
                  </li>
                  <li className={styles.props__item}>
                    <img
                      src={timeIcon}
                      alt="time"
                      className={styles.props__icon}
                    />
                    <p
                      className={styles.props__text}
                    >{`${duration.min} - ${duration.max} ${t("questCard.duration.multi")}`}</p>
                  </li>
                  <li className={styles.props__item}>
                    <img
                      src={distanceIcon}
                      alt="distance"
                      className={styles.props__icon}
                    />
                    <p className={styles.props__text}>{distance} {t("questCard.distance")}</p>
                  </li>
                  <li className={styles.props__item}>
                    <img
                      src={startIcon}
                      alt="start"
                      className={styles.props__icon}
                    />
                    <p className={styles.props__text}>{start}</p>
                  </li>
                  <li className={styles.props__item}>
                    <img
                      src={finishIcon}
                      alt="finish"
                      className={styles.props__icon}
                    />
                    <p className={styles.props__text}>{finish}</p>
                  </li>
                </ul>
              </div>

              <div
                className={cn(styles.section__info, "b-content b-content_lg")}
                dangerouslySetInnerHTML={{ __html: specifics }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestMap;
