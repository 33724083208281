import React from "react";
import cn from "classnames";
import styles from "./Gift.module.scss";

const Gift = React.forwardRef(({ title, desc, imgUrl }, ref) => {
  return (
    <section
      ref={ref}
      className={styles.section}
      style={{
        backgroundImage: imgUrl ? `url(${imgUrl})` : "none",
      }}
    >
      <div className={styles.section__bg}></div>

      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-3">
            <div className={styles.section__main}>
              <h2 className={cn(styles.section__title, "h1", "h1_white")}>
                {title}
              </h2>

              <div
                className={cn(
                  styles.section__desc,
                  "b-content",
                  "b-content_lg",
                  "b-content_white"
                )}
              >
                <p>{desc}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Gift;
