import React from "react";
import cn from "classnames";
import { IMG_URL } from "../../constants";
import styles from "./QuestIt.module.scss";

const QuestIt = React.forwardRef(({ title, items }, ref) => {
  const rightPictures = [
    {
      id: 0,
      image: "girl",
      type: "png",
    },
    {
      id: 1,
      image: "main",
      type: "jpg",
    },
    {
      id: 2,
      image: "top",
      type: "jpg",
    },
    {
      id: 3,
      image: "bottom",
      type: "jpg",
    },
  ];

  return (
    <section ref={ref} className={styles.section}>
      <div className="container">
        <div className="row">
          <div className="col-9 offset-3 order-sm-2 col-sm-4 offset-sm-2 offset-md-0 col-xxl-4 offset-xxl-1">
            <div className={styles.section__right}>
              <div className={styles.picture}>
                {!!rightPictures.length &&
                  rightPictures.map((pic) => (
                    <img
                      key={pic.id}
                      src={`${IMG_URL}/quest-it/${pic.image}.${pic.type}`}
                      alt={pic.image}
                      className={cn(
                        styles.picture__image,
                        styles[`picture__image_${pic.image}`]
                      )}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 offset-md-2 col-xxl-5">
            <div className={styles.section__left}>
              <h2 className={cn(styles.section__title, "h1")}>{title}</h2>

              <ul className={styles.section__list}>
                {!!items.length &&
                  items.map((block) => (
                    <li className={styles.section__item} key={block.id}>
                      <div className={styles.block}>
                        <img
                          className={styles.block__icon}
                          src={block.imgUrl}
                          alt={block.title}
                        />

                        <div
                          className={cn(
                            styles.block__text,
                            "b-content",
                            "b-content_lg"
                          )}
                        >
                          <p>{block.title}</p>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default QuestIt;
