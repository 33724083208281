import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./MobileMenu.module.scss";
import { HOME_PATH, DOMAIN_URL } from "../../constants";
import SelectBlock from "../UI/Select";
import { ReactComponent as In } from "../../assets/icons/social/in.svg";
import { ReactComponent as Vk } from "../../assets/icons/social/vk.svg";
import { ReactComponent as Fb } from "../../assets/icons/social/fb.svg";

const MobileMenu = ({
  className,
  onClose = () => {},
  menu,
  socials,
  onClickMenu = () => {},
}) => {
  const history = useHistory();
  const location = useLocation();
  const {id} = useParams();
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);

  const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);

    if (location.pathname.indexOf('quest') !== -1) {
      history.push(`${DOMAIN_URL}${lang}/quest/${id}`)
    } else if (location.pathname.indexOf('thanks') !== -1) {
      history.push(`${DOMAIN_URL}${lang}/thanks/`)
    } else {
      history.push(`${DOMAIN_URL}${lang}${location.pathname.substring(DOMAIN_URL.length + 3)}/${location.hash}`)
    }
	}

  useEffect(() => {
    setVisible(true);

    const root = document.documentElement;
    const scrollWidth = window.innerWidth - root.clientWidth;
    root.style.paddingRight = scrollWidth + "px";
    root.style.overflow = "hidden";
    document.documentElement.classList.add("open-menu");

    function handleKeyPress(event) {
      if (event.keyCode === 27) onClose();
    }

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      root.style.paddingRight = "0px";
      root.style.overflow = "";
      document.removeEventListener("keydown", handleKeyPress);
      document.documentElement.classList.remove("open-menu");
    };
  }, []);

  const cnModal = cn(className, styles.popup, {
    [styles.popup_visible]: visible,
  });

  return (
    <div className={cnModal}>
      <div className={styles.popup__bg} />
      <nav className={cn(styles.popup__nav, styles.menu)}>
        <ul className={styles.menu__list}>
          {!!menu.data.length &&
            menu.data.map((item) => (
              <li className={styles.menu__item} key={item.id}>
                <a
                  href={`${HOME_PATH()}#${item.section}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname !== HOME_PATH()) {
                      history.push(`${HOME_PATH()}#${item.section}`);
                    } else {
                      onClickMenu(item.section);
                    }
                    onClose();
                  }}
                  className={styles.menu__link}
                >
                  <span>{item.title}</span>
                </a>
              </li>
            ))}
        </ul>
      </nav>

      <div className={styles.popup__langWrap}>
        <SelectBlock onChange={changeLanguage}/>
      </div>

      <footer className={cn(styles.popup__footer, styles.footer)}>
        {!!socials && !!socials.data && !!socials.data.length && (
          <ul className={styles.footer__list}>
            {socials.data.map((item) => (
              <li className={styles.footer__item} key={item.type}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.footer__link}
                >
                  {item.type === "in" && <In />}
                  {/*item.type === "vk" && <Vk />*/}
                  {item.type === "fb" && <Fb />}
                </a>
              </li>
            ))}
          </ul>
        )}

      </footer>
    </div>
  );
};

export default MobileMenu;
