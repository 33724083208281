import { createSlice } from "@reduxjs/toolkit";

import { getMenu as getMenuApi } from "../api";

const initialState = {
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    getMenuStart: (state) => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    getMenuSuccess: (state, { payload }) => {
      state.data = payload;
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getMenuFailure: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = payload.error;
    },
  },
});

export const { getMenuStart, getMenuSuccess, getMenuFailure } =
  menuSlice.actions;

export const getMenu = (lang) => async (dispatch, getState) => {
  // const state = getState();
  // if (state.menu.isLoaded) return;

  dispatch(getMenuStart());
  try {
    const data = await getMenuApi(lang);
    dispatch(getMenuSuccess(data));
  } catch (err) {
    dispatch(getMenuFailure({ error: err.toString() }));
  }
};

export const selectMenu = (state) => state.menu;

export default menuSlice.reducer;
