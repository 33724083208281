import React from "react";
import cn from "classnames";
import { IMG_URL } from "../../constants";
import styles from "./HowItsWorks.module.scss";

const HowItsWorks = React.forwardRef(
  ({ title, desc, items, quest = false }, ref) => {
    return (
      <section
        ref={ref}
        className={cn(styles.section, {
          [styles.section_quest]: quest,
        })}
      >
        <div className="container">
          <div className="row">
            <div className={`${!quest ? "col-md-10" : "col-md-8"} offset-md-2`}>
              <div className={styles.section__main}>
                {!quest ? (
                  <h2 className={cn(styles.section__title, "h1")}>{title}</h2>
                ) : (
                  <h3 className={cn(styles.section__title, "h3")}>{title}</h3>
                )}

                <ul className={styles.section__list}>
                  {!!items.length &&
                    items.map((step, stepIdx) => (
                      <li className={styles.section__item} key={step.id}>
                        <div className={styles.step}>
                          <div className={styles.step__image}>
                            <img
                              src={`${IMG_URL}/how-its-works/${
                                stepIdx + 1
                              }.png`}
                              srcSet={`${IMG_URL}/how-its-works/${
                                stepIdx + 1
                              }@2x.png 2x, ${IMG_URL}/how-its-works/${
                                stepIdx + 1
                              }@3x.png 3x`}
                              alt={`Step ${stepIdx + 1}`}
                            />

                            {/* <img src={step.imgUrl} /> */}
                          </div>

                          <div
                            className={cn(
                              styles.step__desc,
                              "b-content",
                              "b-content_lg"
                            )}
                          >
                            <p>{step.title}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default HowItsWorks;
