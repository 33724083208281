import { createSlice } from "@reduxjs/toolkit";

import { getLanguages as getLanguagesApi } from "../api";

const initialState = {
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    getLangStart: (state) => {
      state.isLoading = true;
    },
    getLangSuccess: (state, { payload }) => {
      state.data = payload;
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getLangFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
  },
});

export const { getLangStart, getLangSuccess, getLangFailure } =
  langSlice.actions;

export const getLang = () => async (dispatch, getState) => {
  const state = getState();
  if (state.menu.isLoaded) return;

  dispatch(getLangStart());
  try {
    const data = await getLanguagesApi();
    dispatch(getLangSuccess(data));
  } catch (err) {
    dispatch(getLangFailure({ error: err.toString() }));
  }
};

export const selectLang = (state) => state.lang;

export default langSlice.reducer;
