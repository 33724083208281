import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Button.module.scss";

const Button = ({
  className,
  children,
  asLink = false,
  to = "/",
  sm = false,
  ...props
}) => {
  if (asLink) {
    return (
      <Link
        className={cn(className, styles.btn, {
          [styles.btn_sm]: sm,
        })}
        to={to}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={cn(className, styles.btn, {
        [styles.btn_sm]: sm,
      })}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
