import React from "react";
import cn from "classnames";
import styles from "./Battery.module.scss";

const Battery = React.forwardRef(({ quest = false, items = [] }, ref) => {
  return (
    <section
      ref={ref}
      className={cn(styles.section, {
        [styles.section_quest]: quest,
      })}
    >
      <div className="container">
        <div className="row">
          <div
            className={cn("col-md-8 offset-md-3 col-lg-7", {
              "offset-lg-3": !quest,
              "offset-lg-2": quest,
            })}
          >
            <ul className={styles.section__main}>
              {items.map((item) => (
                <li key={item.id} className={styles.section__item}>
                  {item.imgUrl && (
                    <img
                      src={item.imgUrl}
                      alt="img"
                      className={styles.section__icon}
                    />
                  )}

                  <div
                    className={cn(
                      styles.section__desc,
                      "b-content",
                      "b-content_lg"
                    )}
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Battery;
