import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: null,
  desc: null
  // title: "Спасибо за ваш интерес",
  // desc: `
  // <p>
  //   На указанный адрес электронной почты придет ссылка на квест.
  //   Обязательно проверьте спам-фильтр, если не получили письмо в
  //   течение 5 минут.
  // </p>

  // <ul>
  //   <li>
  //     До 31 декабря 2021 мы тестируем квесты и даем вам
  //     возможность гулять по городу бесплатно.
  //   </li>
  //   <li>
  //     Если вы оставите отзыв после прогулки, то сможете получить
  //     ваучер на 10.- CHF от партнера Victorinox.
  //   </li>
  // </ul>

  // <p>Желаем приятных открытий!</p>`,
};

export const thxSlice = createSlice({
  name: "thx",
  initialState,
  reducers: {
    setThxMessage: (state, { payload }) => {
      if (payload.title) state.title = payload.title;
      if (payload.desc) state.desc = payload.desc;
    },
  },
});

export const { setThxMessage } = thxSlice.actions;

export default thxSlice.reducer;
