/**
 * Возвращает строку в нужном падеже в зависимости от числительного
 * @param  {Number} number Число
 * @param  {Array} arr Массив строк в порядке возрастания чисел (1, 2-4, 5-10)
 */

export const useStringInArr = (number, arr) => {
  const num = +number;
  const num_1 = +num.toString().substr(-2, 2);
  if (num_1 > 10 && num_1 < 20) {
    return arr[2];
  } else {
    let num_2 = +num.toString().slice(-1);
    if (num_2 === 1) return arr[0];
    if (num_2 > 1 && num_2 < 5) return arr[1];
  }

  return arr[2];
};

export const setCookie = (name,value,days) => {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
