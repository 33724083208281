import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  media: "",
  vh: 0,
  width: 0,
  scrollWidth: 0,
};

export const menuSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMedia: (state, { payload }) => {
      state.vh = payload.vh;
      state.width = payload.width;
      state.scrollWidth = payload.scrollWidth;

      if (payload.width < 768) {
        state.media = "xs";
      } else if (payload.width < 1024) {
        state.media = "sm";
      } else if (payload.width < 1240) {
        state.media = "md";
      } else if (payload.width < 1440) {
        state.media = "lg";
      } else {
        state.media = "xl";
      }
    },
  },
});

export const { setMedia } = menuSlice.actions;

export const selectApp = (state) => state.app;

export default menuSlice.reducer;
