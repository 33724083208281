import React, { useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import HomeLayout from "../../components/HomeLayout";
import styles from "./Thanks.module.scss";
import { IMG_URL, HOME_PATH } from "../../constants";
import Button from "../../components/UI/Button";
import { getPayments } from "../../store/paymentsSlice";
import { getMenu } from "../../store/menuSlice";
import { getSocials } from "../../store/socialsSlice";
import Loader from "../../components/Loader";
import { getCookie, setCookie } from "../../helpers";

const Thanks = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { lang } = useParams();
  const { t } = useTranslation();
  const menu = useSelector((state) => state.menu);
  const socials = useSelector((state) => state.socials);
  const thx = useSelector((state) => state.thx);
  const { data, isLoading } = useSelector((state) => state.payments);

  const url = new URL(window.location);
  const searchGet = (param) => url.searchParams.get(param);

  const paymentKey = searchGet('payment');
  const cookieData = JSON.parse(getCookie(`payment_data_${paymentKey}`));

  useEffect(() => {
    if (cookieData !== null) {
      dispatch(getSocials(lang));
      dispatch(getMenu(lang));
      dispatch(getPayments(cookieData.tour_id, lang, searchGet('type'), cookieData.email));
      setCookie(`payment_data_${paymentKey}`, '', -1);
    } else {
      history.push(HOME_PATH(lang));
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <HomeLayout menu={menu} socials={socials} activeMenuItem="">
      <section className={styles.thx}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className={styles.thx__wrap}>
                <h1 className={cn(styles.thx__title, "h2")}>{data.header}</h1>

                <div
                  className={cn(styles.thx__desc, "b-content b-content_lg")}
                  dangerouslySetInnerHTML={{ __html: data.text }}
                />
                <Button className={styles.thx__back} asLink to={HOME_PATH()}>
                  {t("thanks.btn")}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <img
          src={`${IMG_URL}/thx/girl.png`}
          srcSet={`${IMG_URL}/thx/girl@2x.png 2x, ${IMG_URL}/thx/girl@3x.png 3x`}
          alt="girl"
          className={styles.thx__girl}
        />
        <img
          src={`${IMG_URL}/thx/bubbles.png`}
          srcSet={`${IMG_URL}/thx/bubbles@2x.png 2x, ${IMG_URL}/thx/bubbles@3x.png 3x`}
          alt="bubbles"
          className={styles.thx__bg}
        />
      </section>
    </HomeLayout>
  );
};

export default Thanks;
