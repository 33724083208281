import { createSlice } from "@reduxjs/toolkit";

import { getSocials as getSocialsApi } from "../api";

const initialState = {
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const socialsSlice = createSlice({
  name: "socials",
  initialState,
  reducers: {
    getSocialsStart: (state) => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    getSocialsSuccess: (state, { payload }) => {
      state.data = payload;
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getSocialsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = payload.error;
    },
  },
});

export const { getSocialsStart, getSocialsSuccess, getSocialsFailure } =
  socialsSlice.actions;

export const getSocials = (lang) => async (dispatch, getState) => {
  // const state = getState();
  // if (state.socials.isLoaded) return;

  dispatch(getSocialsStart());
  try {
    const data = await getSocialsApi(lang);
    dispatch(getSocialsSuccess(data));
  } catch (err) {
    dispatch(getSocialsFailure({ error: err.toString() }));
  }
};

export const selectSocials = (state) => state.socials;

export default socialsSlice.reducer;
