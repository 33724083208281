import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import smoothscroll from "smoothscroll-polyfill";
import { setMedia } from "./store/appSlice";
import { getTranslations } from "./store/translationsSlice";
import { getLang } from "./store/langSlice";
import Routing from "./components/Routing";
import language from "./i18n";

import "./assets/styles/bootstrap.scss";
import "./assets/styles/index.scss";

const App = () => {
  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translations.data);
  const startLang = document.querySelector('html').getAttribute('lang');

  useEffect(() => {
    smoothscroll.polyfill();
    dispatch(getLang());
    dispatch(getTranslations());

    function detectBrowser() {
      if (
        (navigator.userAgent.indexOf("Opera") ||
          navigator.userAgent.indexOf("OPR")) !== -1
      ) {
        return "opera";
      } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
        return "chrome";
      } else if (navigator.userAgent.indexOf("Safari") !== -1) {
        return "safari";
      } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        return "firefox";
      } else if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        !!document.documentMode === true
      ) {
        return "ie"; //crap
      } else {
        return "unknown";
      }
    }

    function updateDeviceProps() {
      const height = window.innerHeight;
      const vh = height * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.body.style.setProperty("zoom", 1);

      const root = document.getElementById("root");
      const scrollWidth = window.innerWidth - root.clientWidth;
      document.documentElement.style.setProperty(
        "--scroll-width",
        `${scrollWidth}px`
      );

      dispatch(
        setMedia({
          vh,
          scrollWidth,
          width: document.documentElement.clientWidth,
        })
      );
    }

    document.documentElement.classList.add("browser");
    document.documentElement.classList.add("browser_" + detectBrowser());

    updateDeviceProps();
    window.addEventListener("resize", updateDeviceProps);

    return () => {
      window.removeEventListener("resize", updateDeviceProps);
    };
  }, []);

  return (
    <I18nextProvider i18n={language(startLang, translations)}>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
    </I18nextProvider>
  );
};

export default App;
