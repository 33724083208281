import React, { useState, useRef, useImperativeHandle } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import cn from "classnames";
import Button from "../UI/Button";
import styles from "./MainSlider.module.scss";
import { QUEST_PATH } from "../../constants";
import SwiperCore, { Autoplay } from "swiper/core";
import "swiper/swiper.min.css";
import { useTranslation } from "react-i18next";

SwiperCore.use([Autoplay]);

const MainSlider = React.forwardRef(({ slides }, ref) => {
  const { t } = useTranslation();
  const [activeSlide, setActiveAlide] = useState(0);
  const sect = useRef(null);
  const swiper = useRef(null);

  useImperativeHandle(ref, () => ({
    sect: sect.current,
    playSlider: () => {
      swiper.current.autoplay.start();
    },
    pauseSlider: () => {
      swiper.current.autoplay.stop();
    },
  }));

  return (
    <section ref={sect} className={styles.slider}>
      {!!slides.length && (
        <Swiper
          className={styles.slider__swiper}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          onSwiper={(slider) => (swiper.current = slider)}
          onSlideChange={(e) => {
            setActiveAlide(e.activeIndex);
          }}
        >
          {slides.map((slide, slideIdx) => {
            return (
              <SwiperSlide
                key={slide.id}
                className={cn(styles.slider__slide, styles.slide, {
                  [styles.slide_active]: slideIdx === activeSlide,
                })}
              >
                <div
                  className={styles.slide__bg}
                  style={{
                    backgroundImage: `url(${slide.imgUrl})`,
                  }}
                />

                <div className={styles.slide__wrap}>
                  <h2 className={cn(styles.slide__title, "h1", "h1_white")}>
                    {slide.title}
                  </h2>

                  <div className={styles.slide__desc}>
                    <p>{slide.desc}</p>
                  </div>

                  <Button
                    className={styles.slide__btn}
                    asLink
                    to={`${QUEST_PATH()}${slide.id}`}
                  >
                    {t("home.next")}
                  </Button>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </section>
  );
});

export default MainSlider;
