import { createSlice } from "@reduxjs/toolkit";

import { getTranslations as getTranslationsApi } from "../api";

const initialState = {
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    getTranslationsStart: (state) => {
      state.isLoading = true;
    },
    getTranslationsSuccess: (state, { payload }) => {
      state.data = payload;
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getTranslationsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload.error;
    },
  },
});

export const { getTranslationsStart, getTranslationsSuccess, getTranslationsFailure } =
  translationsSlice.actions;

export const getTranslations = () => async (dispatch, getState) => {
  const state = getState();
  if (state.menu.isLoaded) return;

  dispatch(getTranslationsStart());
  try {
    const data = await getTranslationsApi();
    dispatch(getTranslationsSuccess(data));
  } catch (err) {
    dispatch(getTranslationsFailure({ error: err.toString() }));
  }
};

export const selectTranslations = (state) => state.translations;

export default translationsSlice.reducer;
