import React from "react";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./Partners.module.scss";
import { useTranslation } from "react-i18next";

const Partners = React.forwardRef(({ slides = [] }, ref) => {
  const { t } = useTranslation();
  return (
    <section className={styles.section} ref={ref}>
      <div className="container">
        <div className="row">
          <div className="offset-md-2 col-md-10">
            <div className={styles.section__main}>
              <h2 className={cn(styles.section__title, "h1")}>{t("partners.title")}</h2>

              <Swiper
                loop={false}
                slidesPerView={"auto"}
                freeMode={true}
                watchSlidesVisibility={true}
                watchSlidesProgress={true}
                centeredSlides={false}
                breakpoints={{
                  0: {
                    centeredSlides: true,
                  },
                  768: {
                    centeredSlides: false,
                  },
                }}
                spaceBetween={
                  window.matchMedia("(max-width: 768px)").matches ? 32 : 170
                }
                className={styles.section__swiper}
              >
                {!!slides.length &&
                  slides.map((slide) => {
                    return (
                      <SwiperSlide
                        key={slide.id}
                        className={cn(styles.section__slide, styles.slide)}
                      >
                        <img
                          src={slide.imgUrl}
                          className={styles.slide__img}
                          alt={slide.title}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Partners;
