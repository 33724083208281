import { createSlice } from "@reduxjs/toolkit";

import { getPaymentsTranslations as getPaymentsTranslationsApi } from "../api";

const initialState = {
	data: [],
	isLoading: false,
	isLoaded: false,
	error: null,
};

export const paymentsSlice = createSlice({
	name: "payments",
	initialState,
	reducers: {
		getPaymentsStart: (state) => {
			state.isLoading = true;
			state.isLoaded = false;
		},
		getPaymentsSuccess: (state, { payload }) => {
			state.data = payload;
			state.isLoaded = true;
			state.isLoading = false;
			state.error = null;
		},
		getPaymentsFailure: (state, { payload }) => {
			state.isLoading = false;
			state.isLoaded = false;
			state.error = payload.error;
		},
	},
});

export const getPayments = (id, lang, type, email) => async (dispatch) => {
	dispatch(getPaymentsStart());
	try {
	  const data = await getPaymentsTranslationsApi(id, lang, type, email);

	  dispatch(getPaymentsSuccess(data));
	} catch (err) {
	  dispatch(getPaymentsFailure({ error: err.toString() }));
	}
  };

export const { getPaymentsStart, getPaymentsSuccess, getPaymentsFailure } = paymentsSlice.actions;

export default paymentsSlice.reducer;
