import React, { useState } from "react";
import cn from "classnames";
import { HOME_PATH } from "../../constants";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MobileMenu from "../MobileMenu";
import styles from "./MobileSidebar.module.scss";

const MobileSidebar = ({
  className,
  menu,
  socials,
  onToggleMenu = () => {},
  onClickMenu = () => {},
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [visibleMenu, setVisibleMenu] = useState(false);

  const toggleMenu = () => {
    document.documentElement.style.setProperty(
      "overflow",
      !visibleMenu ? "hidden" : "auto"
    );
    onToggleMenu(!visibleMenu ? "open" : "close");
    setVisibleMenu(!visibleMenu);
  };

  return (
    <div className={cn(className, styles.sidebar)}>
      <div className={styles.sidebar__wrap}>
        <button
          className={cn(styles.sidebar__toggle, styles.burger, {
            [styles.burger_open]: visibleMenu,
            [styles.burger_close]: !visibleMenu,
          })}
          onClick={toggleMenu}
        >
          <span className={styles.burger__icon}>
            <span></span>
          </span>
        </button>

        {!!menu.data.length && (
          <a
            className={cn(styles.sidebar__right, styles.logo)}
            href={`${HOME_PATH()}#${menu.data[0].section}`}
            onClick={(e) => {
              e.preventDefault();
              if (location.pathname !== HOME_PATH()) {
                history.push(`${HOME_PATH()}#${menu.data[0].section}`);
              } else {
                onClickMenu(menu.data[0].section);
              }
            }}
          >
            <div className={styles.logo__img}>
              <img
                src={t("home.logo.xs.src")}
                alt="Swissquest"
                srcSet={`${t("home.logo.xs.srcset@2")} 2x, ${t("home.logo.xs.srcset@3")} 3x`}
              />
            </div>

            <div className={styles.logo__right}>
              <h1 className={styles.logo__swissQuest}>Swiss Quest</h1>

              <div className={styles.logo__walks}>
                <p>{t("home.walks")}</p>
              </div>
            </div>
          </a>
        )}
      </div>

      {visibleMenu && (
        <MobileMenu
          className={styles.sidebar__menu}
          menu={menu}
          socials={socials}
          onClose={() => {
            setVisibleMenu(false);
          }}
          onClickMenu={onClickMenu}
        />
      )}
    </div>
  );
};

export default MobileSidebar;
