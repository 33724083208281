import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import { HOME_PATH, DOMAIN_URL } from "../../constants";
import { useTranslation } from "react-i18next";
import styles from "./Sidebar.module.scss";
import { ReactComponent as In } from "../../assets/icons/social/in.svg";
import { ReactComponent as Vk } from "../../assets/icons/social/vk.svg";
import { ReactComponent as Fb } from "../../assets/icons/social/fb.svg";
import SelectBlock from "../UI/Select";

const Sidebar = ({
  className,
  menu,
  onClickMenu = () => {},
  socials,
  activeMenuItem,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const menuItemsRef = useRef([]);

  const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		history.push(`${DOMAIN_URL}${lang}/${location.pathname.substring(DOMAIN_URL.length + 3)}${location.search}${location.hash}`)
	}

  useEffect(() => {
    menuItemsRef.current.forEach((link) => {
      link.blur();
    });
  }, [activeMenuItem]);

  return (
    <aside className={cn(className, styles.sidebar)}>
      {!!menu.data.length && (
        <a
          href={`${HOME_PATH()}#${menu.data[0].section}`}
          onClick={(e) => {
            e.preventDefault();
            if (location.pathname !== HOME_PATH()) {
              history.push(`${HOME_PATH()}#${menu.data[0].section}`);
            } else {
              onClickMenu(menu.data[0].section);
            }
          }}
          className={styles.sidebar__top}
        >
          <h1 className={cn(styles.sidebar__swissQuest, "h1")}>Swiss Quest</h1>
          <div className={styles.sidebar__walks}>
            <p dangerouslySetInnerHTML={{__html: t("home.title")}}></p>
          </div>
        </a>
      )}

      <nav className={cn(styles.sidebar__nav, styles.menu)}>
        {!!menu.data.length && (
          <ul className={styles.menu__list}>
            {menu.data.map((item, itemIdx) => (
              <li
                className={cn(styles.menu__item, {
                  [styles.menu__item_active]: item.section === activeMenuItem,
                })}
                key={item.id}
              >
                <a
                  href={`${HOME_PATH()}#${item.section}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (location.pathname !== HOME_PATH()) {
                      history.push(`${HOME_PATH()}#${item.section}`);
                    } else {
                      onClickMenu(item.section);
                    }
                  }}
                  className={styles.menu__link}
                  ref={(el) => (menuItemsRef.current[itemIdx] = el)}
                >
                  <span>{item.title}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </nav>

      <div>
        <footer className={cn(styles.sidebar__footer, styles.footer)}>
          {!!socials.data.length && (
            <ul className={styles.footer__list}>
              {socials.data.map((item) => (
                <li className={styles.footer__item} key={item.type}>
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.footer__link}
                  >
                    {item.type === "in" && <In />}
                    {/*item.type === "vk" && <Vk />*/}
                    {item.type === "fb" && <Fb />}
                  </a>
                </li>
              ))}
            </ul>
          )}

          <div className={styles.footer__copy}>
            <p dangerouslySetInnerHTML={{__html: t("home.partner")}}></p>
          </div>

        </footer>

        <div>
            <SelectBlock classname={styles.sidebar__lang} onChange={changeLanguage}/>
        </div>
      </div>

    </aside>
  );
};

export default Sidebar;
