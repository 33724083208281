import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";
import cn from "classnames";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/thumbs/thumbs.min.css";
import styles from "./QuestSlider.module.scss";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrowRight.svg";

SwiperCore.use([Pagination]);

const QuestSlider = ({ slides }) => {
  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const mainSwiperRef = useRef(null);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };

  const updateArrows = () => {
    setIsBeginning(mainSwiperRef.current.isBeginning);
    setIsEnd(mainSwiperRef.current.isEnd);
  };

  return (
    <section className={styles.slider}>
      {!!slides.length && (
        <>
          <div className="container">
            <div className="col-md-8 offset-md-2">
              <button
                className={cn(styles.slider__arrow, styles.slider__arrow_prev)}
                disabled={isBeginning}
                onClick={() => {
                  mainSwiperRef.current.slidePrev();
                  updateArrows();
                }}
              >
                <ArrowLeftIcon />
              </button>
              <button
                className={cn(styles.slider__arrow, styles.slider__arrow_next)}
                disabled={isEnd}
                onClick={() => {
                  mainSwiperRef.current.slideNext();
                  updateArrows();
                }}
              >
                <ArrowRightIcon />
              </button>
            </div>
          </div>
          <Swiper
            loop={false}
            spaceBetween={10}
            onSwiper={(swiper) => {
              mainSwiperRef.current = swiper;
              updateArrows();
            }}
            onSlideChange={() => {
              updateArrows();
            }}
            pagination={pagination}
            className={styles.slider__swiper}
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide.id} className={styles.slider__item}>
                <img src={slide.imgUrl} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </section>
  );
};

export default QuestSlider;
