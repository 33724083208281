import React from "react";
import cn from "classnames";
import styles from "./Loader.module.scss";
import { useTranslation } from "react-i18next";

const Loader = ({title = ''}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.load}>
      <div className={cn(styles.load__text)}>Loading...</div>
    </div>
  );
};

export default Loader;
