import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { Formik, Field } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import close from "../../assets/icons/close.svg";
import Button from "../UI/Button";
import TwoColsText from "../UI/TwoColsText";
import { GOOGLE_RECAPTCHA_CODE_SWISSQUEST, THX_PATH } from "../../constants";
import {buyQuest} from "../../store/questSlice";
import styles from "./QuestOrder.module.scss";

const QuestOrder = ({ id, title, desc, city, price }) => {
  const { t } = useTranslation();
  const [politconf, setPolitconf] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const buy = useSelector((state) => state.quest.data.buy);
  const dispatch = useDispatch();
  const history = useHistory();
  const {lang} = useParams();
  const questOrderRef = useRef();
  const url = new URL(window.location.href);
  const captcha_code = GOOGLE_RECAPTCHA_CODE_SWISSQUEST;

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = t("questOrder.errors.name.required");
    } else if (values.name.trim().length < 3) {
      errors.name = t("questOrder.errors.name.invalid");
    }

    if (!values.email) {
      errors.email = t("questOrder.errors.email.required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t("questOrder.errors.email.invalid");
    }
    return errors;
  };

  const goToThx = () => {
    history.push(THX_PATH());
  };

  const goToUrl = (url) => {
    window.open(url, "_self");
  }

  return (
    <section className={styles.section} ref={questOrderRef}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-2">
            <h3 className={cn(styles.section__title, "h3")}>{title}</h3>

            <div className={cn(styles.section__city, "b-content b-content_lg")}>
              <p>{city}</p>
            </div>
          </div>

          <div className="col-md-2 offset-md-2">
            <div className={styles.section__right}>
              <div className={styles.section__price}>
                {/*<p>10 {t("questOrder.currency")}*}</p>*/}
                <p>{price} {t("questOrder.currency")}</p>
              </div>
              {/*
              <div className={styles.section__action}>
                <p>* {t("questOrder.sale_till")} 27.02.2023</p>
              </div>
              */}
              <div className={styles.section__buy}>
                <Button
                  sm
                  disabled={openForm}
                  onClick={(e) => {
                    e.target.blur();
                    questOrderRef.current.scrollIntoView({behavior: "smooth"});
                    setOpenForm(!openForm);
                  }}
                >
                  {t("questOrder.btns.buy")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openForm && (
        <div className={styles.section__form}>
          <Formik
            initialValues={{ name: "", email: "" }}
            validate={validate}
            onSubmit={(values) => {
              dispatch(buyQuest(id, values, lang, goToThx, goToUrl));
            }}
          >
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                <button
                  className={styles.form__close}
                  onClick={() => {
                    setOpenForm(false);
                  }}
                >
                  <img src={close} alt="close" />
                </button>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6 offset-sm-2">
                      <h3 className={styles.form__title}>{t("questOrder.title")}</h3>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 offset-sm-2">
                      <div
                        className={cn(
                          styles.form__field,
                          styles.form__field_name,
                          {
                            [styles.form__field_error]: !!errors.name 
                          }
                        )}
                      >
                        <Field id="name" name="name" placeholder={t("questOrder.placeholders.name")} />
                        <span className="error">{errors.name}</span>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div
                        className={cn(
                          styles.form__field,
                          styles.form__field_email,
                          {
                            [styles.form__field_error]: !!errors.email 
                          }
                        )}
                      >
                        <Field
                          id="email"
                          name="email"
                          placeholder={t("questOrder.placeholders.email")}
                          type="email"
                        />
                        <span className="error">{errors.email}</span>
                        
                      </div>
                    </div>

                    <div className="col-sm-4 offset-sm-2">
                      <ReCAPTCHA
                        className={styles.form__recaptcha}
                        sitekey={captcha_code}
                        hl={lang}
                        onChange={(v) => {
                          setRecaptcha(true);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 offset-sm-2">
                      <div className={styles.form__checkbox}>
                        <label htmlFor="politconf">
                          <input
                            type="checkbox"
                            name="politconf"
                            id="politconf"
                            defaultChecked={politconf}
                            onChange={(e) => {
                              setPolitconf(e.target.checked);
                            }}
                          />
                          <span dangerouslySetInnerHTML={{__html: t("questOrder.policy")}}></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 offset-sm-2">
                      <Button
                        sm
                        className={styles.form__submit}
                        type="submit"
                        disabled={
                          !politconf ||
                          !recaptcha ||
                          !!Object.keys(errors).length ||
                          buy.isLoading
                        }
                      >
                        {buy.isLoading ? t("questOrder.btns.sending") : t("questOrder.btns.pay")}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}

      <TwoColsText text={desc} />
    </section>
  );
};

export default QuestOrder;
