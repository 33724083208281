import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../../pages/Home";
import Quest from "../../pages/Quest";
import Thanks from "../../pages/Thanks";
import NotFound from "../../pages/NotFound";
import {
  HOME_PATH,
  QUEST_PATH,
  THX_PATH,
  NOT_FOUND_PATH,
} from "../../constants";

const Routing = () => {
  const router = [
    {
      type: "route",
      exact: true,
      path: HOME_PATH(':lang'),
      component: Home,
    },
    {
      type: "route",
      path: `${QUEST_PATH(':lang')}:id`,
      component: Quest,
    },
    {
      type: "route",
      path: THX_PATH(':lang'),
      component: Thanks,
    },
    {
      type: "route",
      path: NOT_FOUND_PATH(':lang'),
      component: NotFound,
    },
  ];

  return (
    <Switch>
      {router.map((item) => {
        const { type, ...other } = item;
        const { path } = other;
        const MyRouter = type === "redirect" ? Redirect : Route;
        return <MyRouter key={path} {...other} />;
      })}
      <Redirect from="*" to={`${NOT_FOUND_PATH()}`} />
    </Switch>
  );
};

export default Routing;
