import { createSlice } from "@reduxjs/toolkit";
import { getQuests as getQuestsApi } from "../api";

const initialState = {
  // ...mock,
  data: [],
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const questsSlice = createSlice({
  name: "quests",
  initialState,
  reducers: {
    getQuestsStart: (state) => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    getQuestsSuccess: (state, { payload }) => {
      const newData = payload.map((quest) => {
        return {
          id: quest.id,
          imgUrl: quest.card_picture || "",
          title: quest.title || "",
          desc: quest.description || "",
          rating: quest.info.avr_rating,
          way: `${quest.info.start} - ${quest.info.finish}`,
          duration: quest.info.duration || {},
          city: quest.city || "",
          price: quest.price || "",

          page: {
            slider: quest.images.map((pic, picIdx) => ({
              id: picIdx,
              imgUrl: pic || "",
            })),
            info: {
              title: quest.title || "",
              city: quest.city || "",
              desc: quest.description || "",
              price: quest.price,
            },
            way: {
              rating: quest.info.avr_rating,
              duration: quest.info.duration || { min: 0, max: 0 },
              distance: quest.info.distance || 0,
              start: quest.info.start || "",
              finish: quest.info.finish || "",
              specifics: quest.info.specifics,
              geo: quest.info.geo,
            },
          },
        };
      });
      state.data = newData;
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getQuestsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = payload.error;
    },
  },
});

export const { getQuestsStart, getQuestsSuccess, getQuestsFailure } =
  questsSlice.actions;

export const getQuests = (lang) => async (dispatch, getState) => {
  // const state = getState();
  // if (state.quests.isLoaded) return;

  dispatch(getQuestsStart());
  try {
    const data = await getQuestsApi(lang);
    dispatch(getQuestsSuccess(data));
  } catch (err) {
    dispatch(getQuestsFailure({ error: err.toString() }));
  }
};

export const selectQuests = (state) => state.quests;
export const selectQuestFilters = (state) => {
  let newFilterList = [];

  state.quests.data.forEach((quest, questIdx) => {
    if (newFilterList.findIndex((fl) => fl.name === quest.city) === -1) {
      newFilterList.push({
        id: quest.id,
        name: quest.city,
      });
    }
  });

  return newFilterList;
};

export default questsSlice.reducer;
