import React, { useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./QuestComments.module.scss";
import Comment from "../Comment";
import refreshIcon from "../../assets/icons/refresh.svg";

const QuestComments = ({ comments }) => {
  const MAX_SHOW = 6;
  const [startIdx, setStartIdx] = useState(0);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const nextComments = () => {
    const nextIdx = (startIdx + MAX_SHOW) % comments.length;
    if (nextIdx + MAX_SHOW > comments.length)
    {
      setStartIdx(comments.length - MAX_SHOW);
    } else {
      setStartIdx(nextIdx);
    }
  };

  return (
    <section className={styles.section}>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-2">
            <div className={styles.section__head}>
              <h3 className={cn(styles.section__title, "h3")}>
                {t("quest.comments")}
              </h3>

              {MAX_SHOW < comments.length && (
                <button
                  className={styles.section__refresh}
                  onClick={nextComments}
                >
                  <img src={refreshIcon} alt="refresh" />
                </button>
              )}
            </div>

            <div className={cn(styles.section__comments, styles.comments)}>
              <div className={styles.comments__row}>
                {!!comments.length &&
                  comments.slice(startIdx, startIdx + MAX_SHOW).map((comment) => (
                    <div className={styles.comments__col} key={comment.id}>
                      <Comment className={styles.comments__card} {...comment} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestComments;
