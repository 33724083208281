const checkSlash = (url) => {
  if (!!url && typeof url === "string") {
    return url.substr(-1) !== "/" ? url + "/" : url;
  }
  return undefined;
};

const IS_MOCK_API = process.env.REACT_APP_MOCK_API;
const API_URL = checkSlash(process.env.REACT_APP_API_URL);
const MOCK_API_URL = checkSlash(process.env.REACT_APP_MOCK_API_URL);
const DOMAIN_URL = checkSlash(process.env.REACT_APP_DOMAIN_URL);

const HOME_PATH = (lp) => {
  const lang = typeof lp === 'undefined' ? localStorage.getItem('i18nextLng') || 'en' : lp
  return `${checkSlash(`${process.env.REACT_APP_DOMAIN_URL}${lang}`) || `/${lang}/`}`
}
const HOME_QUEST_PATH = (lp) => {
  const lang = typeof lp === 'undefined' ? localStorage.getItem('i18nextLng') || 'en' : lp
  return `${checkSlash(`${process.env.REACT_APP_DOMAIN_URL}${lang}`) || `/${lang}/`}#quests`
}
const QUEST_PATH = (lp) => {
  const lang = typeof lp === 'undefined' ? localStorage.getItem('i18nextLng') || 'en' : lp
  return `${checkSlash(`${process.env.REACT_APP_DOMAIN_URL}${lang}`) || `/${lang}/`}quest/`
}; // prettier-ignore
const NOT_FOUND_PATH = (lp) => {
  const lang = typeof lp === 'undefined' ? localStorage.getItem('i18nextLng') || 'en' : lp
  return `${checkSlash(`${process.env.REACT_APP_DOMAIN_URL}${lang}`) || `/${lang}/`}404/`
}; // prettier-ignore
const THX_PATH = (lp) => {
  const lang = typeof lp === 'undefined' ? localStorage.getItem('i18nextLng') || 'en' : lp
  return `${checkSlash(`${process.env.REACT_APP_DOMAIN_URL}${lang}`) || `/${lang}/`}thanks/`
}; // prettier-ignore

const ROOT_PATH = checkSlash(process.env.PUBLIC_URL) || '/';
const IMG_URL = `${ROOT_PATH}assets/images`;

const GOOGLE_RECAPTCHA_CODE = process.env.REACT_APP_GOOGLE_RECAPTCHA_CODE;
const GOOGLE_RECAPTCHA_CODE_SWISSQUEST = process.env.REACT_APP_GOOGLE_RECAPTCHA_CODE_SWISSQUEST;

export {
  ROOT_PATH,
  IS_MOCK_API,
  API_URL,
  MOCK_API_URL,
  HOME_PATH,
  QUEST_PATH,
  NOT_FOUND_PATH,
  THX_PATH,
  HOME_QUEST_PATH,
  IMG_URL,
  GOOGLE_RECAPTCHA_CODE,
  GOOGLE_RECAPTCHA_CODE_SWISSQUEST,
  DOMAIN_URL
};
