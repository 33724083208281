import React from "react";
import cn from "classnames";
import styles from "./Comment.module.scss";
import starRedIcon from "../../assets/icons/star.svg";
import starGrayIcon from "../../assets/icons/starGray.svg";

const Comment = ({
  className,
  author = "",
  rating = 0,
  date = "",
  message = "",
}) => {
  return (
    <div className={cn(className, styles.comment)}>
        <div className={cn(styles.comment__author, "b-content b-content_md")}>
          <p>{author ? author : "<имя не указано>"}</p>
        </div>

      <div className={styles.comment__info}>
        <div className={cn(styles.comment__rating, styles.rating)}>
          <ul className={styles.rating__list} title={`Оценка: ${rating}`}>
            {new Array(5).fill("").map((i, idx) => (
              <li className={styles.rating__item} key={idx}>
                <img
                  src={idx < rating ? starRedIcon : starGrayIcon}
                  alt={idx}
                  className={styles.rating__star}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className={cn(styles.comment__date, "b-content b-content_md")}>
          <p>{date}</p>
        </div>
      </div>

      <div className={cn(styles.comment__text, "b-content b-content_lg")}>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Comment;
