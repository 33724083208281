import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import QuestCard from "../QuestCard";
import { selectQuestFilters } from "../../store/questsSlice";
import styles from "./Quests.module.scss";

const Quests = React.forwardRef(({ quests }, ref) => {
  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState(t("quests.btns"));
  const filterList = useSelector(selectQuestFilters);

  const filterListRef = useRef(null);
  const filterItemsRef = useRef({});

  useEffect(() => {
    setActiveFilter(t("quests.btns"))
  }, [t("quests.btns")])

  useEffect(() => {
    let navWidth = 0;

    Object.keys(filterItemsRef.current).map((key) => {
      if (filterItemsRef.current[key] !== null) {
        navWidth += filterItemsRef.current[key].offsetWidth + 12;
      }
    });
    navWidth += 4;

    filterListRef.current.style.setProperty("width", navWidth + "px");
  }, [quests]);

  return (
    <section ref={ref} className={styles.section}>
      <div className={cn(styles.section__container, "container")}>
        <div className="row">
          <div className="col-md-8 offset-md-4 col-xxl-8 offset-xxl-3">
            <div className={styles.section__wrap}>
              <h2 className={cn(styles.section__title, "h1")}>{t("quests.title")}</h2>

              <div className={styles.section__filter}>
                <nav className={styles.filter}>
                  <ul className={styles.filter__list} ref={filterListRef}>
                    <li
                      className={styles.filter__item}
                      ref={(el) => {
                        filterItemsRef.current[`all`] = el;
                      }}
                    >
                      <button
                        className={cn(styles.filter__btn, {
                          [styles.filter__btn_active]: t("quests.btns") === activeFilter,
                        })}
                        onClick={() => setActiveFilter(t("quests.btns"))}
                      >
                        {t("quests.btns")}
                      </button>
                    </li>

                    {!!filterList.length &&
                      filterList.map((filter, filterIdx) => (
                        <li
                          className={styles.filter__item}
                          key={filter.id}
                          ref={(el) => {
                            filterItemsRef.current[`city-${filterIdx}`] = el;
                          }}
                        >
                          <button
                            className={cn(styles.filter__btn, {
                              [styles.filter__btn_active]:
                                filter.name === activeFilter,
                            })}
                            onClick={() => setActiveFilter(filter.name)}
                          >
                            {filter.name}
                          </button>
                        </li>
                      ))}
                  </ul>
                </nav>
              </div>

              <div className={styles.section__body}>
                {quests.isLoaded &&
                  !!quests.data.length &&
                  quests.data
                    .filter(
                      (q) => activeFilter === t("quests.btns") || q.city === activeFilter
                    )
                    .map((quest) => (
                      <div className={styles.section__item} key={quest.id}>
                        <QuestCard
                          quest={quest}
                          className={styles.section__card}
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Quests;
