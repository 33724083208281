import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

const language = (lang, translations) => {
	i18n
		.use(initReactI18next)
		.use(LanguageDetector)
		.init({
			// Standard language used
			fallbackLng: lang,
			debug: false,
			detection: {
				order: ['localStorage', 'queryString'],
				caches: ['localStorage']
			},
			backend: {
				backends: [LocalStorageBackend],
				backendOptions: [
					{
						expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
					}
				]
			},
			resources: translations,
			react: {
				useSuspense: false
			},
			interpolation: {
				escapeValue: false
			}
		});
}

export default language;
