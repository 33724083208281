import axios from "axios";

import { IS_MOCK_API, API_URL, MOCK_API_URL } from "../constants";

const { origin } = window.location;
let url = (IS_MOCK_API === "true" ? MOCK_API_URL : API_URL) || origin;

// /site/pages/main
export const getPageHome = async (lang) => {
  return await axios.get(`${url}site/pages/main?lang=${lang}`).then((res) => {
    return res.data;
  });
};

// /site/pages/quest
export const getPageQuest = async (lang) => {
  return await axios.get(`${url}site/pages/quest?lang=${lang}`).then((res) => {
    return res.data;
  });
};

// /site/menu
export const getMenu = async (lang) => {
  return await axios.get(`${url}site/menu?lang=${lang}`).then((res) => {
    return res.data;
  });
};

// /site/socials
export const getSocials = async (lang) => {
  return await axios.get(`${url}site/socials?lang=${lang}`).then((res) => {
    return res.data;
  });
};

// /site/quests
export const getQuests = async (lang) => {
  return await axios.get(`${url}site/quests?lang=${lang}`).then((res) => {
    return res.data;
  });
};

// /site/quests/id
export const getQuest = async (id, lang) => {
  return await axios.get(`${url}site/quests/${id}?lang=${lang}`).then((res) => {
    return res.data;
  });
};

// /site/quests/id/feedbacks
export const getComments = async (id, lang) => {
  return await axios.get(`${url}site/quests/${id}/feedbacks?lang=${lang}`).then((res) => {
    return res.data;
  });
};

// /site/quests/id/buy
export const buyQuest = async (id, props, lang) => {
  return await axios.post(`${url}site/quests/${id}/buy?lang=${lang}`, props).then((res) => {
    return res.data;
  });
};

// site/payments/translations/
export const getPaymentsTranslations = async (id, lang, type, email) => {
  return await axios.post(`${url}site/payments/translations?tour_id=${id}&locale=${lang}&type=${type}&email=${email}`).then((res) => {
    return res.data;
  });
};

// site/languages
export const getLanguages = async () => {
  return await axios.get(`${url}site/languages`).then((res) => {
    return res.data;
  });
};

// site/languages/set
export const setLanguages = async (lang) => {
  return await axios.post(`${url}site/languages/set`, `locale=${lang}`).then((res) => {
    return res.data;
  });
};

// site/languages/translations
export const getTranslations = async () => {
  return await axios.get(`${url}site/languages/translations`).then((res) => {
    return res.data;
  });
};