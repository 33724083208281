import { createSlice } from "@reduxjs/toolkit";

import { getPageHome as getPageHomeApi } from "../api";

const initialState = {
  data: {},
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    getHomeStart: (state) => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    getHomeSuccess: (state, { payload }) => {
      const newData = {};
      newData.slider = {
        items: payload.slider.map((slide) => {
          return {
            id: slide.id,
            title: slide.city || "",
            desc: slide.quest_title || "",
            imgUrl: slide.image || "",
          };
        }),
      };
      newData.whatIsIt = {
        title: payload.what_is_it.title || "",
        items: payload.what_is_it.items.map((item) => {
          return {
            id: item.id,
            title: item.text || "",
            imgUrl: item.image || "",
          };
        }),
      };
      newData.howItsWorks = {
        title: payload.how_it_works.title || "",
        desc: payload.how_it_works.description || "",
        items:
          payload.how_it_works.steps.map((step) => {
            return {
              id: step.id,
              title: step.title || "",
              imgUrl: step.image || "",
            };
          }) || [],
      };
      newData.battery = {
        items:
          !!payload.how_it_works && Array.isArray(payload.how_it_works.items)
            ? payload.how_it_works.items.map((item) => {
                return {
                  id: item.id,
                  desc: item.text || "",
                  imgUrl: item.image || "",
                };
              })
            : [],
      };
      newData.gift = {
        title: payload.special_offer.title || "",
        desc: payload.special_offer.description || "",
        imgUrl: payload.special_offer.background || "",
      };
      newData.partners = payload.partners.map((partner) => {
        return {
          id: partner.id,
          title: partner.title || "",
          imgUrl: partner.image || "",
        };
      });
      newData.aboutUs = {
        title: payload.about_us.title || "",
        desc: payload.about_us.description || "",
      };
      state.data = newData;
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
    },
    getHomeFailure: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = payload.error;
    },
  },
});

export const { getHomeStart, getHomeSuccess, getHomeFailure } =
  homeSlice.actions;

export const getHome = (lang) => async (dispatch, getState) => {
  // const state = getState();
  // if (state.home.isLoaded) return;

  dispatch(getHomeStart());
  try {
    const data = await getPageHomeApi(lang);
    dispatch(getHomeSuccess(data));
  } catch (err) {
    dispatch(getHomeFailure({ error: err.toString() }));
  }
};

export const selectHome = (state) => state.home;

export default homeSlice.reducer;
