import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResizeObserver from "resize-observer-polyfill";
import MainSlider from "../../components/MainSlider";
import QuestIt from "../../components/QuestIt";
import HowItsWorks from "../../components/HowItsWorks";
import Battery from "../../components/Battery";
import Gift from "../../components/Gift";
import Quests from "../../components/Quests";
import Partners from "../../components/Partners";
import AboutUs from "../../components/AboutUs";
import Loader from "../../components/Loader";
import { getHome } from "../../store/homeSlice";
import { getQuests } from "../../store/questsSlice";
import { getMenu } from "../../store/menuSlice";
import { getSocials } from "../../store/socialsSlice";
import { Helmet } from 'react-helmet';

import HomeLayout from "../../components/HomeLayout";
import { HOME_PATH } from "../../constants";

const Home = () => {
  const {lang} = useParams();
  const { hash } = useLocation();
  const [isLoadedHash] = useState(hash);
  const history = useHistory();
  const [activeMenuItem, setActiveMenuItem] = useState(hash || "main");
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const quests = useSelector((state) => state.quests);
  const menu = useSelector((state) => state.menu);
  const socials = useSelector((state) => state.socials);

  const { i18n } = useTranslation();

  let sectRefs = useRef({});

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    dispatch(getMenu(lang));
    dispatch(getSocials(lang));
    dispatch(getHome(lang));
    dispatch(getQuests(lang));
  }, [lang]);


  useEffect(() => {
    if (home.isLoaded && quests.isLoaded && !!isLoadedHash) {
      handleGoTo(isLoadedHash.substr(1));
    }
  }, [home.isLoaded, quests.isLoaded, isLoadedHash]);

  useEffect(() => {
    if (hash !== activeMenuItem) {
      history.push(`${HOME_PATH(lang)}#${activeMenuItem}`);
    }
  }, [activeMenuItem, lang]);

  useEffect(() => {
    if (home.isLoaded && quests.isLoaded) {
      const root = document.body;
      let newPosSect = {};

      const getPosSect = () => {
        if (!Object.keys(sectRefs.current).length) return;
        Object.keys(sectRefs.current).forEach((sect) => {
          if (sectRefs.current[sect] !== null) {
            newPosSect[sect] = {
              top: !!sectRefs.current[sect].sect
                ? sectRefs.current[sect].sect.offsetTop
                : 0,
              bottom: !!sectRefs.current[sect].sect
                ? sectRefs.current[sect].sect.offsetTop +
                  sectRefs.current[sect].sect.offsetHeight
                : 0,
            };
          }
        });
      };
      getPosSect();

      const resizeObserver = new ResizeObserver(getPosSect);
      resizeObserver.observe(root);

      const scrollEvent = () => {
        const st = document.documentElement.scrollTop;
        const vh = window.innerHeight * 0.01;
        const centerLine = st + vh * 25;

        Object.keys(newPosSect).forEach((sect) => {
          if (
            centerLine >= newPosSect[sect].top &&
            centerLine <= newPosSect[sect].bottom
          ) {
            setActiveMenuItem(sect);
          }
        });
      };

      scrollEvent();
      document.addEventListener("scroll", scrollEvent);

      return () => {
        document.removeEventListener("scroll", scrollEvent);
        resizeObserver.unobserve(root);
      };
    }
  }, [home.isLoaded, quests.isLoaded]);

  useEffect(() => {
    document.title = "Swiss Quest"
  }, []);

  const handleGoTo = (link) => {
    const root = document.documentElement;

    if (!sectRefs.current[link].sect) return;
    // let scrollTop = 0;
    //
    // if (window.innerWidth >= 1024) {
    //   if (sectRefs.current[link].sect.offsetHeight < window.innerHeight) {
    //     scrollTop =
    //         sectRefs.current[link].sect.offsetTop -
    //         (window.innerHeight - sectRefs.current[link].sect.offsetHeight) / 2;
    //   } else {
    //     scrollTop = sectRefs.current[link].sect.offsetTop;
    //   }
    // } else {
    //   const style =
    //       sectRefs.current[link].sect.currentStyle ||
    //       window.getComputedStyle(sectRefs.current[link].sect);
    //
    //   scrollTop =
    //       sectRefs.current[link].sect.offsetTop + parseInt(style.marginTop) * -1;
    // }

    const scrollTop = sectRefs.current[link].sect.offsetTop;
    root.scrollTo({
      top: scrollTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleToggleMenu = (status) => {
    if (home.data.slider.items.length >= 1) {
      if (status === "open") sectRefs.current.main.pauseSlider();
      if (status === "close") sectRefs.current.main.playSlider();
    }
  };

  if (!home.isLoaded) {
    return <Loader />;
  }

  return (
    <HomeLayout
      menu={menu}
      socials={socials}
      activeMenuItem={activeMenuItem}
      onToggleMenu={handleToggleMenu}
      onClickMenu={handleGoTo}
    >
      <Helmet>
        <title>Swiss Quest</title>
      </Helmet>

      {!!home.data.slider && !!home.data.slider.items && (
        <MainSlider
          ref={(ref) => {
            sectRefs.current.main = ref;
          }}
          slides={home.data.slider.items}
        />
      )}

      {!!home.data.whatIsIt && (
        <QuestIt
          ref={(ref) => {
            sectRefs.current.whatIsIt = {
              sect: ref,
            };
          }}
          title={home.data.whatIsIt.title}
          items={home.data.whatIsIt.items}
        />
      )}

      {!!home.data.howItsWorks && (
        <HowItsWorks
          ref={(ref) => {
            sectRefs.current.howItsWorks = {
              sect: ref,
            };
          }}
          title={home.data.howItsWorks.title}
          desc={home.data.howItsWorks.desc}
          items={home.data.howItsWorks.items}
        />
      )}

      {!!home.data.battery && !!home.data.battery.items.length && (
        <Battery
          ref={(ref) => {
            sectRefs.current.battery = {
              sect: ref,
            };
          }}
          items={home.data.battery.items}
        />
      )}

      {!!home.data.gift && (
        <Gift
          ref={(ref) => {
            sectRefs.current.gift = {
              sect: ref,
            };
          }}
          title={home.data.gift.title}
          desc={home.data.gift.desc}
          imgUrl={home.data.gift.imgUrl}
        />
      )}

      {!!quests && (
        <Quests
          quests={quests}
          ref={(ref) => {
            sectRefs.current.quests = {
              sect: ref,
            };
          }}
        />
      )}

      {!!home.data.partners && (
        <Partners
          ref={(ref) => {
            sectRefs.current.partners = {
              sect: ref,
            };
          }}
          slides={home.data.partners}
        />
      )}

      {!!home.data.aboutUs && (
        <AboutUs
          ref={(ref) => {
            sectRefs.current.aboutUs = {
              sect: ref,
            };
          }}
          title={home.data.aboutUs.title}
          content={home.data.aboutUs.desc}
        />
      )}
    </HomeLayout>
  );
};

export default Home;
