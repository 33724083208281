import React, { useMemo } from "react";
import cn from "classnames";
import styles from "./TwoColsText.module.scss";

const TwoColsText = ({ text }) => {
  const split = (s = "") => {
    const arr = s.replace(/&nbsp;/g, " ").split("");
    const arrLength = arr.length;
    const arrCenter = Math.floor(arrLength * 0.6);
    const cnt = s.split(".").length;

    if (arrLength > 300 || cnt > 3) {
      let res = arrCenter;

      for (let i = 0; i < arrCenter; i++) {
        if (arr[arrCenter + i] === ".") {
          res = arrCenter + i + 1;
          break;
        }
        if (arr[arrCenter - i] === ".") {
          res = arrCenter - i + 1;
          break;
        }
      }

      return [s.substr(0, res), s.substr(res)];
    } else {
      return [s];
    }
  };

  const memoizedText = useMemo(() => split(text), [text]) || [];

  return (
    <div className="container">
      <div className="row">
        <div
          className={cn(
            {
              "col-md-4": memoizedText.length === 2,
              "col-md-8": memoizedText.length === 1,
            },
            "offset-md-2"
          )}
        >
          <div
            className={cn(
              styles.col__desc,
              styles.col__desc_1,
              "b-content b-content_lg"
            )}
          >
            <p dangerouslySetInnerHTML={{ __html: memoizedText[0] }} />
          </div>
        </div>

        {memoizedText.length === 2 && (
          <div className="col-md-4">
            <div
              className={cn(
                styles.col__desc,
                styles.col__desc_2,
                "b-content b-content_lg"
              )}
            >
              <p dangerouslySetInnerHTML={{ __html: memoizedText[1] }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TwoColsText;
