import React from "react";
import cn from "classnames";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import { setLanguages } from "../../../api";
import { HOME_PATH } from "../../../constants";

import styles from './index.module.scss';
import './index.scss';

const SelectBlock = ({classname, onChange}) => {
	const location = useLocation();
	const history = useHistory();
	const {lang} = useParams();
	const langs = useSelector(state => state.lang.data);

	const options = langs.map(n => {
		return {
			value: n.code,
			label: n.code
		}
	});

	const defaultValue = (options, value) => {
		return options ? options.find((option) => option.value === value) : "";
	};

	return (
		<div className={cn(classname, styles.selectBlock)}>
			<Select 
				hideSelectedOptions={true}
				menuPlacement='top'
				isSearchable={false}
				classNamePrefix="react-select"
				onChange={(e) => {
					onChange(e.value);
					setLanguages(e.value);

					if (location.pathname.indexOf('thanks') !== -1) {
						history.push(HOME_PATH(lang));
					}
				}}
				value={defaultValue(options, lang)}
				className={styles.selectBlock__select}
				options={options}/>
		</div>
	)
}

export default SelectBlock;