import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./QuestSidebar.module.scss";
import { useTranslation } from "react-i18next";
import {HOME_PATH, IMG_URL, DOMAIN_URL, QUEST_PATH, HOME_QUEST_PATH} from "../../constants";
import SelectBlock from "../UI/Select";

import homeIcon from "../../assets/icons/share/home.svg";
import shareIcon from "../../assets/icons/share/share.svg";
import closeIcon from "../../assets/icons/share/close.svg";
import vkIcon from "../../assets/icons/share/vk.svg";
import fbIcon from "../../assets/icons/share/fb.svg";
// import inIcon from "../../assets/icons/share/in.svg";
// import twIcon from "../../assets/icons/share/tw.svg";
import waIcon from "../../assets/icons/share/wa.svg";
import questIcon from "../../assets/icons/share/compass.svg"

const QuestSidebar = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const {id} = useParams();
  const [fixed, setFixed] = useState(false);
  const [minHeight, setMinHeight] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		history.push(`${DOMAIN_URL}${lang}/quest/${id}`)
	}

  const url = encodeURIComponent(window.location.href);

  useEffect(() => {
    const handleScroll = () => {
      setFixed(
        document.documentElement.scrollTop >=
          document.documentElement.offsetHeight * 0.5 - 100 - 156
      );
    };
    handleScroll();

    const handleResize = () => {
      setMinHeight(document.documentElement.offsetHeight <= 920);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <aside className={styles.sidebar}>
      <div to={HOME_PATH()} className={styles.sidebar__logo}>
          <span className={styles.sidebar__smlogo}>
            Swiss
            <br />
            Quest
          </span>
      </div>

      <div
        className={cn(styles.sidebar__main, {
          [styles.sidebar__main_fixed]: fixed,
        })}
      >
        <div className={styles.sidebar__wrap}>

          <Link
            to={HOME_PATH()}
            className={styles.sidebar__home}
            style={{ opacity: minHeight && shareOpen ? 0 : 1 }}
          >
            <img src={homeIcon} alt="home" title={t("questSidebar.tooltip_home")}/>
          </Link>
          <Link
            to={HOME_QUEST_PATH()}
            className={styles.sidebar__home}
            style={{ opacity: minHeight && shareOpen ? 0 : 1 }}
          >
            <img src={questIcon} alt="quests" title={t("questSidebar.tooltip_compass")}/>
          </Link>

          <div className={styles.sidebar__share}>
            <div className={styles.share}>
              <nav className={styles.share__buttons}>
                {shareOpen && (
                  <ul className={styles.share__list}>
                    <li className={styles.share__item}>
                      <a
                        href={`whatsapp://send?text=SwissQuest ${document.title} ${url}`}
                        target="_blank"
                        rel="noreferrer"
                        data-action="share/whatsapp/share"
                        className={styles.share__btn}
                      >
                        <img src={waIcon} alt="twitter" />
                      </a>
                    </li>
                    {/* <li className={styles.share__item}>
                      <a
                        href={`https://twitter.com/share?url=${url}`}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.share__btn}
                      >
                        <img src={twIcon} alt="twitter" />
                      </a>
                    </li> */}
                    {/* <li className={styles.share__item}>
                    <a
                      href="/"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.share__btn}
                    >
                      <img src={inIcon} alt="instagram" />
                    </a>
                  </li> */}
                    <li className={styles.share__item}>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.share__btn}
                      >
                        <img src={fbIcon} alt="facebook" />
                      </a>
                    </li>
                    <li className={styles.share__item}>
                      <a
                        href={`http://vkontakte.ru/share.php?url=${url}`}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.share__btn}
                      >
                        <img src={vkIcon} alt="vk" />
                      </a>
                    </li>
                  </ul>
                )}
              </nav>

              <button
                className={cn(styles.share__toggle, {
                  [styles.share__toggle_open]: shareOpen,
                  [styles.share__toggle_close]: !shareOpen,
                })}
                onClick={() => {
                  setShareOpen(!shareOpen);
                }}
              >
                <img src={!shareOpen ? shareIcon : closeIcon} alt="open" />
              </button>
            </div>
          </div>

          <SelectBlock classname={styles.sidebar__lang} onChange={changeLanguage}/>
        </div>
      </div>
    </aside>
  );
};

export default QuestSidebar;
