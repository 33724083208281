import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import styles from "./QuestLayout.module.scss";
import QuestSidebar from "../QuestSidebar";
import MobileSidebar from "../MobileSidebar";

const QuestLayout = ({ 
  children, 
  menu, 
  socials 
}) => {
  let location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={styles.quest}>
      <div className={styles.quest__sidebarWrap}>
        <QuestSidebar />
      </div>

      <div className={styles.quest__sidebarMobile}>
        <MobileSidebar menu={menu} socials={socials} />
      </div>

      <main className={styles.quest__main} id="loyout">
        {children}
      </main>
    </div>
  );
};

export default QuestLayout;
